import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={[
        `bai chan kheo`,
        `portfolio`,
        `developer`,
        `software`,
        `react`,
      ]}
    />
    <h1>Hi, I'm Bai Chan.</h1>
    <p>I build things. This is one of them. But it's not done yet.</p>
  </Layout>
);

export default IndexPage;
